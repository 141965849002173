
import { defineComponent, ref, toRef } from "vue";

export default defineComponent({
  props: {
    visible: Boolean,
  },
  setup(props, { emit }) {
    const dialogVisible = ref(toRef(props, "visible"));
    const qrCodeList = [
      {
        name: "杨经理",
        tel: "176-8897-3639",
        img: "/img/home/yanglei-qrcode.png",
      },
      {
        name: "刘经理",
        tel: "182-0071-7927",
        img: "/img/home/liuhuang-qrcode.png",
      },
      {
        name: "朱经理",
        tel: "130-9882-9836",
        img: "/img/home/zhushinu-qrcode.png",
      },
    ];

    const dialogClose = () => {
      emit("hidden", false);
    };

    return {
      dialogVisible,
      qrCodeList,
      dialogClose,
    };
  },
});
