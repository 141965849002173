
import { defineComponent, ref } from "vue";
import Dialog from "@/components/ElDialog.vue";

export default defineComponent({
  components: {
    Dialog,
  },
  setup() {
    const isImageVisible = ref(false);
    const showImage = () => {
      isImageVisible.value = true;
    };
    const hideImage = () => {
      isImageVisible.value = false;
    };

    const topList = [
      {
        img: "t1.png",
        name: "Online Usage Experience",
      },
      {
        img: "t2.png",
        name: "Multi scenario product support",
      },
      {
        img: "t3.png",
        name: "1V1 Customer Service",
      },
      {
        img: "t4.png",
        name: "7X24 Hour Service",
      },
    ];
    const linkList = [
      {
        title: "Corporate Governance",
        links: [
          {
            // value: "/product/digital_marriage",
            value: "",
            label: "Board of Directors & Executive Officers",
          },
          {
            id: 1,
            value: "",
            label: "Committee Composition",
          },
          {
            id: 2,
            value: "",
            label: "Governance Documents",
          },
        ],
      },
      {
        title: "PressReleases",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "",
          },
        ],
      },
      {
        title: "Investor Kits",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "Company Profile",
          },
          {
            id: 1,
            value: "",
            label: "Investor Presentations",
          },
          {
            id: 2,
            value: "",
            label: "Webcast & Events",
          },
        ],
      },
      {
        title: "FinancialInformation",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "SEC Filings",
          },
          {
            id: 1,
            value: "",
            label: "Interim Reports",
          },
          {
            id: 2,
            value: "",
            label: "Annual Reports",
          },
        ],
      },
      {
        title: "StockInfo",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "",
          },
        ],
      },
      {
        title: "ShareholderServices",
        links: [
          {
            // value: "/solution/consumer_finance",
            value: "",
            label: "Investor Inquiries",
          },
          {
            id: 1,
            value: "",
            label: "IR Contact",
          },
          {
            id: 2,
            value: "",
            label: "Annual General Meeting",
          },
        ],
      },
    ];
    const dialogVisible = ref(false);
    // 关闭弹窗
    const hidden = (val: boolean) => {
      dialogVisible.value = val;
    };

    const toTop = () => {
      window.scrollTo(0, 0);
    };

    return {
      topList,
      linkList,
      dialogVisible,
      hidden,
      toTop,
      isImageVisible,
      showImage,
      hideImage,
    };
  },
});
